@coinWidth: 200px;
@coinWidthMobile: 100px;
@coinFormColor: #d0a30d;

html.coinflip {
    height: auto;
    background: #000 url("img/background-coin.jpg") 100% 50% no-repeat;
    background-size: cover;
}

body#coinflip {
    height: auto;
    //height: calc(~"100% - 50px");
    //padding-bottom: 50px;
    position: relative;

    color: #eee;
    line-height: normal;

    h1 {
        font-family: "Open Sans Condensed", sans-serif;
        color: #9e1616;
        font-weight: 700;
        font-size: 4.5rem;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);

        @media (max-width: @mobileBreakpoint) {
            font-size: 2.5rem;
            margin: 0;
        }

        span {
            animation: color-change 1s infinite;
        }
    }

    header {
        background: transparent;
        min-height: 0;

        #logo {
            position: static;
            background-color: rgb(0, 0, 0, .75);
            padding: 5px 10px 10px 10px;
            border-radius: 0px 0px 10px 10px;
            display: block;
            margin: 0 auto;
            animation: .75s ease-out 0s 1 slideInFromTop;
            width: 80px;
        }
    }

    #topslot { margin-top: 10px; }
    #bottomslot { margin-bottom: 10px; }

    @media (max-width: @mobileBreakpoint) {
        #topslot { display: block; }
    }

    footer {
        //position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        //background-color: rgba(20, 108, 154, .5);
        background-color: #146c9a;
        font-size: .8rem;
        padding: 0;

        a {
            font-weight: 400;
            letter-spacing: .05em;
        }

        & > .container {
            padding: 10px;
        }
    }

    #fliprules {
        color: #fff;
        text-align: center;
        padding: 0 10px;

        h2 { 
            color: #fff; 
            animation: color-change 1s infinite;
            text-shadow: 1px 2px rgba(0, 0, 0, .3);
        }
        p { font-size: .8em; }

    }
}

#flipContainer {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
}

#flipContainer.signup + #belowCoins {
    margin-top: 25px;
}

#flipChoice, #flipResult {
    position: absolute;
    width: calc(~"100% - 20px");
    top: -40px;
    background-color: rgba(0, 0, 0, .95);
    padding: 10px;
    display: none;
    text-align: center;

    &.signup {
        position: static;
        background-color: rgba(0, 0, 0, .85);
        border-radius: 5px;
    }

    .coinChoice {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 15px;

        .coin {
            padding: 10px;
            flex-basis: 30%;
            border-radius: 15px;
            transition: background-color .25s;
            cursor: pointer;

            &:hover {
                background-color: #000;
            }

            h5 { font-weight: 700; text-transform: uppercase; font-size: .9rem; margin: 10px 0 0 0; color: #e9b60c; }
        }
    }

    @media (max-width: @mobileBreakpoint) {
    }

    &.show {
        display: block;
    }

    strong {
        color: #e9b60c;
        text-transform: uppercase;
    }

    .small { font-size: .8rem; }

    a {
        color: #d0a30d;
    }
}

#flipgame {
    width: calc(~"100% - 20px");
    //height:40vh;
    overflow:visible;
    position:relative;
    margin: 50px auto 15px;
    margin-top: 50px;
    padding: 0 10px;
    max-width: (@coinWidth * 3) + 30px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

#belowCoins {
    margin-top: 120px;
}

.coinBox {
    display: block;
    position:relative;
    height: @coinWidth;
    width: @coinWidth;
    border: 0px solid white;
    margin-bottom: 15px;

    @media (max-width: @mobileBreakpoint) {
        height: @coinWidthMobile;
        width: @coinWidthMobile;
    }

    .coinHeads, .coinEdge, .coinLine, .coinTails {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: @coinWidth;
        height: @coinWidth;

        @media (max-width: @mobileBreakpoint) {
            height: @coinWidthMobile;
            width: @coinWidthMobile;
        }
    }

    .coinHeads {
        z-index:30;
    }

    .coinEdge {
        z-index:20;
    }

    .coinLine {
        top: round(@coinWidth/2);
        height:0px;
        z-index:10;

        @media (max-width: @mobileBreakpoint) {
            top: round(@coinWidthMobile/2);
        }
    }

    .coinTails {
        z-index:40;
        opacity:0;
    }
}

#coinCountdown { font-size: 10em; }


#entry {
    max-width: 500px;
    margin: 0 auto 20px;

    fieldset {
        border-radius: 15px;
        padding: 15px;
        border: 2px solid @coinFormColor;

        legend {
            color: @coinFormColor;
            font-size: 1.7em;
            font-weight: 700;
            padding: 0 15px;
            margin: 0 auto;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 1em;
            }
        }

        h2 {
            line-height: 1.4em;
            color: @coinFormColor;

            strong {
                color: #E86F51;
            }
        }
    }

    div.error {
        background-color: #c35353;
        margin-bottom: 10px;
        border: 1px solid #ea4646;
        padding: 5px;
        color: #fff;
        font-size: .9em;
    }

    @media screen and (-moz-images-in-menus: 0) {
        fieldset {
            position: relative;
        }

        fieldset>legend {
            position: absolute;
            left: 50%;
            top: -35px; /* depends on font size and border */
            width: 75%;
            transform: translate(-50%, 0);
        }
    }

    input[type="text"], input[type="email"], input[type="password"] {
        display: block;
        width: calc(~"100% - 22px");
        font-size: 1em;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid @coinFormColor;
        margin-bottom: 15px;

        @media (max-width: @mobileBreakpoint) {
            font-size: .9em;
        }
    }

    #passwordField {
        margin-bottom: 15px;
    }
    #password {
        margin-bottom: 2px;
    }

    .input.fake {
        width: calc(~"100% - 22px");
        font-size: 1em;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid @coinFormColor;
        margin-bottom: 15px;
        position: relative;
        text-align: left;
        background-color: #fff;

        @media (max-width: @mobileBreakpoint) {
            font-size: .9em;

            label.title {
                font-size: 11px;
                display: block;
                position: absolute;
                top: -17px;
            }
        }

        label {
            color: #757575;
        }
    }

    #buttonGroup {
        overflow: hidden;
        position: relative;

        #titleButtons {
            width: 80px;
            position: absolute;
            top: 1px;
            left: 1px;

            button {
                cursor: pointer;
                display: block;
                float: left;
                padding: 7px 0 6px;
                font-weight: bold;
                font-size: 12px;
                border: 0;
                width: 40px;
                text-align: center;
                color: #000;
                background: linear-gradient(to bottom, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
                border-right: 1px solid #333;

                &:focus { outline:0; }
                &:hover { color: #fff; }
                &.active {
                    color: #fff;
                    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .25)
                }
            }

            #mr { 
                border-radius: 3px 0 0 3px;

                &:hover, &.active {
                    background: linear-gradient(to bottom, #3c69ad, darken(#3c69ad, 20%));
                }
            }

            #ms { 
                border-radius: 0 3px 3px 0;

                &:hover, &.active {
                    background: linear-gradient(to bottom, @pink, darken(@pink, 20%));
                }
            }
        }

        input[type='text']{
            padding: 5px 10px 5px 87px;
            width: calc(~"100% - 99px");
        }


    }

    #submit, #spin_again, #showSignupForm, #emailSignup {
        display: block;
        width: 80%;
        margin: 0 auto;
        font-size: 1.3em;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);
        text-align: center;
        cursor: pointer;
        padding: 10px;
        background: linear-gradient(to bottom, @coinFormColor, darken(@coinFormColor, 20%));
        border: 1px solid lighten(@coinFormColor, 15%);
        transition: background .5s;

        border-radius: 10px;

        &:focus { outline:0; }
        &:hover {
            background: linear-gradient(to bottom, darken(@coinFormColor, 5%), darken(@coinFormColor, 25%));
        }

        @media (max-width: @mobileBreakpoint) {
            color: #fff;
            font-size: 1em;
        }

    }

    #fbsignup {
        display: block;
        width: 80%;
        margin: 0 auto 25px;
        font-size: 1.3em;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);
        text-align: center;
        cursor: pointer;
        padding: 10px;
        background-color: #3b5998;
        border: 1px solid #2d4373;
        transition: background-color .5s;

        border-radius: 10px;

        @media (max-width: @mobileBreakpoint) {
            margin-bottom: 10px;
        }

        .logo {
            fill: #fff;
            width: 1em;
            height: 1em;
            display: block;
            float: left;
        }

        &:hover {
            background-color: #2d4373;   
        }

        @media (max-width: @mobileBreakpoint) {
            font-size: 1em;
        }

    }


    .disclaimer {
        color: #949292;
        font-size: .7em;
        text-align: justify;
        margin-top: 15px;
        line-height: 1.2em;

        p:last-child { margin-bottom: 0;}
    }


    #thank_you {
        background-color: #0A0D2E;

        .btn {
            text-shadow: 1px 1px rgba(0, 0, 0, .25);
            background-color: #F93E1D;
        }

        & > .container {
            padding: 0;
            max-width: 100%;
            position: relative;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        #thanksimg {
            display: block;
            margin: 0 auto;
        }

        #thanksbutton {
            position: absolute;
            display: block;
            right: 25px;
            top: calc(~"50% - 76px");
            max-width: 300px;
            border-radius: 6px;
            background: linear-gradient(to bottom, #FEC70B 0%, #bb971d 47%, #7d630b 100%);
            box-shadow: 1px 1px 1px rgba(0,0,0,.25);

            color: #efefef;
            text-decoration: none;
            padding: 10px 15px;
            text-align: center;
            font-size: 1.5em;
            line-height: 1.4em;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            text-shadow: 1px 1px rgba(0, 0, 0, .25);

            @media (max-width: @mobileBreakpoint) {
                position: static;
                max-width: 100%;
                border-radius: 0;
                font-size: 1.2em;
                box-shadow: none;
            }
        }
    }
}

#rules {
    color: #949292;
    font-size: .7em;
    text-transform: capitalize;
    line-height: 1.2em;

    @media (max-width: @mobileBreakpoint) {
        text-align: left;
        text-transform: none;
    }
}

#threecoin {
    max-width: 300px;
    margin: 0 auto 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    img {
        display: block;
        width: 90px;
        height: 96px;

        @media (max-width: @mobileBreakpoint) {
            width: 70px;;
            height: 75px;
        }
    }
}

@media (max-width: @mobileBreakpoint) {
    .validate {
        font-size: .7em;
        margin-bottom: 8px;
    }

    .congrats { font-size: 1.5em !important; }
}

.entrybutton {
    display: block;
    width: 80%;
    margin: 0 auto;
    font-size: 1.3em;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff !important;
    text-shadow: 1px 1px rgba(0, 0, 0, .25);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    padding: 10px;
    background: linear-gradient(to bottom, @coinFormColor, darken(@coinFormColor, 20%));
    border: 1px solid lighten(@coinFormColor, 15%);
    transition: background .5s;

    border-radius: 10px;

    &:focus { outline:0; }
    &:hover {
        background: linear-gradient(to bottom, darken(@coinFormColor, 5%), darken(@coinFormColor, 25%));
    }

    @media (max-width: @mobileBreakpoint) {
        color: #fff;
        font-size: .8em !important;
    }

}

@keyframes color-change {
    0% { color: #fff; }
    20% { color: #f26b51; }
    40% { color: #53c2bd; }
    60% { color: #4a8cc9; }
    80% { color: #f067aa; }
    100% { color: #fff; }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@import (inline) "animate.css";
