@import (inline) "reset.css";

@primaryColor: #1A2165;
@highlight: #fcd378;
@red: #771251;
@pink: #f271af;
@2kColor: #3c69ad;

//Window Sizes
@tabletBreakpoint: 768px;
@mobileLandscapeBreakpoint: 667px;
@mobileBreakpoint: 500px;

html { height: 100%; background-color: #F7F7F7; }

body {
    color: #111;
    font-family: "Open Sans", sans-serif;
    line-height: 1.4em;

    &.fbfooter {
        padding-bottom: 80px;

        @media (max-width: @mobileBreakpoint) {
            padding-bottom: 40px;
        } 
    }
}

.mobile-only, .offerButton.mobile-only {
    display: none;

    @media (max-width: @mobileBreakpoint) {
        display: block;
    }
}

.mobile-hide {
    @media (max-width: @mobileBreakpoint) {
        display: none !important;
    }
}

#topslot {
    @media (max-width: @mobileBreakpoint) {
        display: none;
    }
}

p { margin-bottom: 25px; }
strong { font-weight: 700; }
small { font-size: .8em; }

.container {
    max-width: 1070px;
    margin: 0 auto;
    padding: 0 15px;
    overflow: hidden;

    @media (max-width: @mobileBreakpoint) {
        padding: 0 10px;
    }

    &.slot {
        padding: 30px 15px;
    }
}

.hidden { display: none; }
.mobile-only {
    display: none;

    @media (max-width: @mobileBreakpoint) {
        display: block;
    }
}


.align-center { text-align: center; }

h1 {
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 2em;
    font-weight: 700;
    text-transform: uppercase;
    margin: 15px 0 25px;
    color: @red;
    text-align: center;
    line-height: 1.4em;

    strong {
        font-size: 2em;
        @media (max-width: @mobileBreakpoint) {
            font-size: 1.3em;
        }
    }
}

h2 {
    text-align: center;
    margin: 15px 0 20px;
    color: #166b9a;
    line-height: 1.4em;
    font-weight: 700;
    font-size: 1.9em;
    text-transform: uppercase;
    //text-shadow: 1px 1px rgba(0, 0, 0, .25);
}

h3 {
    font-weight: 400;
    font-size: 1.2em;
    text-transform: uppercase;
    margin: 15px 0 8px;
}

header {
    position: relative;
    background: #1572b3 url("img/header_bg.jpg") 50% 50% no-repeat;
    background-size: cover;

    color: #fff;

    #headline {
        color: @highlight; 
        font-family: "Open Sans Condensed", sans-serif;
        font-size: 4em;
        text-align: center;
        word-spacing: .7em;
        letter-spacing: .1em;
        text-transform: uppercase;
        text-shadow: 1px 4px 4px rgba(0, 0, 0, .25);
        padding: 0;
        margin: 10px 0;

        @media (max-width: @tabletBreakpoint) {
            font-size: 2.5em;
        }

        @media (max-width: @mobileBreakpoint) {
            padding: 15px 0;
            font-size: 1.7em;
            word-spacing: .5em;
            margin: 0;
        }
    }

    #topnav {
        text-align: center;
        color: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, .25);

        @media (max-width: @mobileBreakpoint) {
            display: none;
        }

        & > .container {
            padding: 5px;
            display: flex;
            max-width: 800px;
            justify-content: space-between;
            align-items: center;


            a {
                color: #fff;
                text-transform: uppercase;
                font-weight: bold;
                text-decoration: none;
                text-shadow: 1px 1px rgba(0,0,0,.25);
                //flex-basis: 15%;
                //font-size: .9em;
                order: 2;
                
                &.login {
                    color: @highlight;
                }

                & > img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        &.loggedin {
            #headerlogo {
                order: 1;
            }
        }
    }

    #mobileheader {
        display: none;
        color: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, .25);
        position: relative;
        padding: 5px 0;
        text-align: center;

        #burger {
            position: absolute;
            top: 21px;
            right: 10px;
            font-size: 2em;
            padding: 5px;
            border-radius: 5px;
            background-color: rgba(255, 255, 255, .2);
            color: @highlight;
            text-decoration: none;

        }

        @media (max-width: @mobileBreakpoint) {
            display: block;
        }
    }

    #mobilenav {
        display: none;
        border-top: 1px solid #7db3d8;

        @media (min-width: @mobileBreakpoint) {
            display: none !important;
        }

        a {
            display: block;
            background-color: #418dc1;
            padding: 10px 15px;
            color: #fff;
            text-decoration: none;
            text-align: left;
            font-size: .9em;
            border-bottom: 1px solid #7db3d8;

            &.login {
                color: @highlight;
            }
        }
    }

}

#tiles, .tiles {
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &.center {
        justify-content: space-around;
    }

    .tile {
        //flex-basis: 30%;
        width: 300px;
        background-color: #ccc;
        margin-bottom: 25px; 
        background-color: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, .25);
        text-align: center;
        color: #636363;
        transition: background-color .25s, color .25s;
        margin: 5px;

        &.half {        flex-basis: 46%; width: auto; }
        &.twothirds {   flex-basis: 65%; }

        @media (max-width: @tabletBreakpoint) {
            justify-content: space-around;
            //flex-basis: 49%;
            &.half {        flex-basis: 100%; }
            &.twothirds {   flex-basis: 100%; }
            &.medianet { order: 999; }
        }

        @media (max-width: @mobileBreakpoint) {
            //flex-basis: 100%;

            &.twothirds { display: none; }

            &:nth-last-child(1):nth-child(odd){
                //flex-grow: 2;
            }
        }

        &:hover {
            background-color: #418dc1;
            color: #fff;

            .pulse();
            animation-duration: .5s;
            animation-fill-mode: both;

            a { color: #fff; }
        }

        img {
            max-width: 100%;
            width: 100%;
            height: auto;
        }

        h3 {
            display: block;
            padding: 15px;
            font-weight: 400;
            font-size: .9em;
            min-height: 30px;

            a {
                text-decoration: none;
                color: #636363;
            }
        }

        &.fuze {
            h3 {
                min-height: 0;
                padding: 0;
                margin: 0;
            }
        }
    }

    &.mobile-tile {
        @media (max-width: @mobileLandscapeBreakpoint) {
            .twothirds {
                display: none;
            }
        }

        @media (max-width: @mobileBreakpoint) {
            .tile {
                //flex-basis: 49%;

                &.fuze {
                    flex-basis: 100%;
                }

                &.fuze.mid {
                    order: 10;
                }

                &.last {
                    //flex-basis: 100%;
                }
            }
        }
    }

    &.mobile-scroll {
        @media (max-width: @mobileBreakpoint) {
            overflow-x: scroll;
            flex-wrap: nowrap;
            padding-bottom: 4px;
            margin-top: 0;

            .tile {
                min-width: 300px;
                width: 300px;
                margin: 0 5px;

                &:first-child {
                    order: 2;
                }

                &.twothirds {
                    display: none;
                }
            }

            .tile:first-child {
                margin-left: 0;
            }
        }
    }
}

body.offer #relatedOffers .tiles {
    @media (max-width: @mobileBreakpoint) {
        .tile:nth-child(1) { order: 2; }
        .tile:nth-child(2) { order: 1; }
        .tile:nth-child(3) { order: 3; }
    }
}

#offer, .offer-single, .offer-single-content {
    overflow: hidden;

    & > .container {
        padding-top: 15px;
        padding-bottom: 10px;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .offer, .copy { float: left; }
    .offer { 
        width: 300px; margin-right: 40px; 

        img {
            max-width: 100%;
            height: auto;
        }
    }
    .copy { 
        width: calc(~"100% - 340px"); 

        h2 {
            color: @red;
            font-family: "Open Sans", sans-serif;
            font-size: 1.7em;
            margin-top: 0;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-weight: 400;
            line-height: 1.2em;
            border-bottom: 1px solid #d8d5d5;
            padding-bottom: 4px;
        }

        .desc {
            font-size: .9rem;
            line-height: 1.3rem;

            p:last-child {
                margin-bottom: 0;
            }
        }

        p {
            margin-bottom: 15px;
        }

        .offerButton {
            margin-top: 15px;
        }

    }

    @media (max-width: @tabletBreakpoint) {
        .offer, .copy { float: none; margin: 0; width: 100%; }
        .offer { 
            margin-bottom: 15px; text-align: center; 

            h2 { text-align: center; }
        }
    }

    @media (max-width: @mobileBreakpoint) {
        .copy {
            h2 {
                font-size: 1.5em;
                line-height: 1.2em;
            }
        }
    }

    #mobileofferbtn {
        margin: 10px auto 0;
    }

}

.offerButton {
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1px 1px rgba(0, 0, 0, .25);

    display: block;
    background-color: #761251;
    transition: background-color .5s;
    margin: 50px auto 0;
    max-width: 400px;
    border-radius: 5px;
    padding: 20px 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);

    .pulse();
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;

    &:hover {
        animation-name: none;
        //background-color: #41880b;
    }
}

#home .offer-single, #midoffer, #bottomoffer {
    background-color: #e8e8e8;

    .container {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

#midoffer, #bottomoffer {
    padding-top: 15px;
}

#relatedOffers {
    & > .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    background-color: #fff;

    h4 {
        text-transform: uppercase;
        color: #418dc1;
        font-size: 1.4em;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 700;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
    }
}

#locker, .locker {
    color: #fff;
    background-color: #418dc1;
    padding: 15px 0 5px 0;
    margin-bottom: 30px;

    img {
        max-width: 100%;
        height: auto;
    }

    .mobile { display: none; }
    @media (max-width: @mobileBreakpoint) {
        .mobile { display: block; }
        .desktop { display: none; }
    }

    .container {
        padding: 0 0 30px;
    }

    h2 {
        text-align: center;
        margin-bottom: 25px;
        color: #fff;
        line-height: 1.4em;
        font-weight: 700;
        font-size: 1.9em;
        text-transform: uppercase;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);

        @media (max-width: @mobileBreakpoint) {
            font-size: 1.3em;
        }
    }
}

#blog {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

    h2 {
        text-align: center;
        font-family: "Open Sans Condensed", sans-serif;
        text-transform: uppercase;
        font-size: 3em;
        font-weight: 700;
        color: #146c9a;
        margin-bottom: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 2px solid rgba(20, 108, 154, 0.55);
        line-height: 1.4em;

        @media (max-width: @mobileBreakpoint) {
            font-size: 1.6em;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        & > div { background-color: #fff; padding: 25px; border-radius: 10px; }

        #newestPost { 

            flex-basis: 57%;

            @media (max-width: 800px) {
                flex-basis: calc(~"100% - 50px");
            }
        }

        #olderPosts {
            flex-basis: 30%;

            h2 {
                font-size: 1.1em;
                font-weight: 700;
                text-transform: uppercase;
                font-family: "Open Sans Condensed", sans-serif;
                color: @red;
                margin-bottom: 15px;
                padding: 5px;
                border-bottom: 1px solid @red;
            }

            @media (max-width: 800px) {
                flex-basis: calc(~"100% - 50px");
                justify-content: space-between;
                background-color: #e4e4e4;

                .oldposts {
                    display: flex;
                    flex-wrap: wrap;

                    .oldpost { flex-basis: 48%; }
                }
            }

            @media (max-width: 650px) {
                .oldposts {
                    .oldpost { 
                        flex-basis: 100%; 
                        text-align: center;
                        
                        & > a { float: none; margin-right: 0; margin-bottom: 10px; width: 100%; }
                        & > .copy { float: none; width: 100%; }
                    }
                }
            }

            h3 {
                font-size: 1em;

                a {
                    text-decoration: none;
                    color: #333;
                }
            }

            .oldpost {
                overflow: hidden;
                margin-bottom: 10px;
                padding: 5px;
                border-bottom: 1px solid @red;

                & > a {
                    display: block;
                    float: left;
                    width: 150px;
                    margin-right: 15px;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                & > .copy {
                    float: left;
                    width: calc(~"100% - 165px");
                }
            }

            .gotoblog {
                background-color: @red;
                padding: 7px 10px;
                border-radius: 5px;
                display: block;
                margin: 0 auto;
                text-align: center;
                transition: background .5s;

                color: #fff;
                font-size: .9em;
                font-family: "Open Sans Condensed", sans-serif;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                
                &:hover {
                    background-color: lighten(@red, 10%);
                }

            }
        }

    }

    #newestPost, article {

        h3 { 
            color: @red; font-size: 1.8em; line-height: 1.3em; 

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .postimg {
            margin-bottom: 15px;
            img {
                max-width: 100%;
                height: auto;
            }
        }

        .readmore {
            background-color: @red;
            padding: 10px 15px;
            border-radius: 5px;
            display: inline-block;
            transition: background .5s;

            color: #fff;
            font-size: 1.3em;
            font-family: "Open Sans Condensed", sans-serif;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;

            &:hover {
                background-color: lighten(@red, 10%);
            }
        }
    }

    .carousel {
        overflow: hidden;
        width: 30000em;

        article {
            float: left;
            max-width: 60vw;
            margin: 0 20vw;

            @media (max-width: @mobileBreakpoint) {
                max-width: 90vw;
                margin: 0 5vw;
            }

            .postimg { text-align: center; }

            h3 {
                @media (max-width: @mobileBreakpoint) {
                    font-size: 1.3em;
                }
            }


            img { max-width: 100%; height: auto; }
        }
    }

    a.prev, a.next {
        position: absolute;
        top: 300px;
        cursor:pointer;
        display: inline-block;

        &.disabled {
            opacity: .2;
            cursor: initial;
        }
    }

    a.prev { left: 13vw; }
    a.next { right: 13vw; }

    @media (max-width: @mobileBreakpoint) {
        a.prev, a.next {
            top: 200px;
            background-color: rgba(255, 255, 255, .25);
            padding: 5px;
        }

        a.prev { left: 20px; }
        a.next { right: 20px; }
    }
}


footer {
    background-color: #146c9a;
    padding: 25px 0;

    color: #fff;
    font-size: 1.1em;
    text-align: center;

    a {
        display: inline-block;
        color: #82c3d7 !important;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0 20px;
        transition: color .5s;

        &:hover {
            color: #b7e1ef;
        }

    }

    @media (max-width: @tabletBreakpoint) {
        & > .container {
            display: flex;
            flex-wrap: wrap;
        }

        a {
            display: block;
            font-size: .9em;
            font-weight: 400;
            padding: 4px 0;
            margin-bottom: 4px;

            flex-basis: 50%;
        }

        span { display: none; }
    }

    @media (max-width: @mobileBreakpoint) {
        & > .container {
            display: block;
        }

        text-align: left;
        padding: 10px 0;

        a {
        }

        span { display: none; }
    }


}

#fbfooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #002157;
    min-height: 80px;
    text-align: center;
    
    @media (max-width: @mobileBreakpoint) {
        min-height: 40px;

        .fb-like {
            position: absolute;
            top: 5px;
            right: 50px;
        }
    }

    .container {
        padding-top: 15px;

        @media (max-width: @mobileBreakpoint) {
            padding-top: 8px;
        }
    }

    h5 {
        color: #fff;
        font-size: 1.3em;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 10px;

        @media (max-width: @mobileBreakpoint) {
            font-size: .8em;
            margin-bottom: 0;
            text-align: left;
        }

    }


    .closecontainer {
        position: absolute;
        bottom: 0;
        right: 0;
        min-height: 80px;
        background-color: #000b1d;
        width: 80px;

        .close {
            position: relative;
            bottom: 14px;
            display: block;
            width: 80px;
            min-height: 80px;
            transform: skewY(-20deg);
            background-color: #011941;
            line-height: 80px;
            text-decoration: none;

            span {
                display: block;
                text-align: center;
                font-size: 2em;
                color: #768398;
                transform: skewY(20deg);
            }
        }

        @media (max-width: @mobileBreakpoint) {
            min-height: 40px;
            width: 40px;

            .close {
                min-height: 40px;
                line-height: 40px;
                width: 40px;
                bottom: 7px;

                span {
                    font-size: 1.1em;
                }
            }
        }
    }
}


@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}


@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}


.pulse {
  animation-name: pulse;
}

@import (inline) "remodal.css";
@import (inline) "remodal-default-theme.css";
//Remodal Overides

div.remodal {
    border-radius: 5px;


    h2 {
        color: #6f6f6f;
        font-family: "Open Sans Condensed", sans-serif;
        font-size: 1.8em;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 1.4em;
    }

    .input {
        margin-bottom: 8px;
        
        input[type="text"], input[type="email"], input[type="password"] {
            background-color: #fff;
            width: calc(~"100% - 32px");
            font-size: .8em;
            border-radius: 3px;
            border: 0;
            padding: 10px 15px;
        }

        &.fake {
            background-color: #fff;
            color: #716f6f;
            text-align: left;
            width: calc(~"100% - 32px");
            border-radius: 3px;
            font-size: .8em;
            border: 1px solid #cdd0d0;
            padding: 6px 15px;

            label {
                padding-right: 5px;
            }

            .block {
                display: inline-block;
            }

            input[type='text']{
                padding: 0 10px;
                width: calc(~"100% - 20px");
            }

        }

        @media (max-width: @mobileBreakpoint) {
            &.fake.zipdob {
                width: 100%;
                border: 0;
                padding: 0 !important;

                input[type='text']{
                    padding: 10px;
                    width: calc(~"100% - 20px");
                    margin-bottom: 8px;
                }
            }
        }
    }

    button#login {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: 1em;
        font-weight: 700;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);

        display: block;
        width: 80%;
        cursor: pointer;
        background-color: #761251;
        transition: background-color .5s;
        margin: 15px auto 5px;;
        border: 0;
        border-radius: 5px;
        padding: 10px 30px;

        @media (max-width: @mobileBreakpoint) {
            width: 100%;
        }
    }
}

#joinLabel, .joinLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;


    input {
        display: block;
        width: 20px;
        margin-right: 5px;
        text-align: left;
    }

    & > span {
        display: block;
        font-size: .9em;
        line-height: 1.3em;
        color: #5f5f5f;
        text-align: left;
    }
}

#formLogin, .formLogin {
  display: block;
  margin: 15px auto;
  text-align: center;
  font-size: .8em;
  color: lighten(#3c69ad, 10%);
  background-color: aliceblue;
  padding: 4px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color .5s, color .5s;

  &:hover {
    background-color: #3c69ad;
    color: #fff !important;
  }
}

#loginModal {
    max-width: 600px;

    #loginContent { 
        overflow: hidden;

        & > .logo {
            float: left;
            width: 200px;
            border-right: 1px solid #a3a6a6;
            margin-right: 15px;
            margin-top: 43px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        & > form {
            display: block;
            float: left;
            width: calc(~"100% - 216px");

            a {
                color: #167896;
                text-decoration: none;
            }
        }

        div.error {
            font-size: 11px;
            color: #fff;
            background-color: darkred;
            line-height: 1.2em;
            padding: 5px;
            font-weight: 700;
            margin-bottom: 5px;
        }


        @media (max-width: @mobileBreakpoint) {
            & > .logo {
                float: none;
                width: auto;
                margin: 0 0 5px 0;
                border: 0;
            }

            & > form {
                float: none;
                width: auto;
            }
        }
    }

    #thankyoumessage {
        h2 {
            color: #761251;
            text-shadow: 1px 4px 4px rgba(0, 0, 0, .25);
        }

        #logo {
            margin: 0 auto;

            img { max-width: 100%; height: auto; }

            @media (max-width: @mobileBreakpoint) {
                max-width: 150px;
            }
        }

        .logo {
            img { max-width: 100px; }
        }

        a.close {
            text-decoration: none;
            text-align: center;
            color: #fff;
            font-size: 1.2em;
            font-weight: 700;
            text-shadow: 1px 1px rgba(0, 0, 0, .25);

            display: block;
            background-color: #761251;
            transition: background-color .5s;
            margin: 20px auto 0;
            max-width: 600px;
            border-radius: 5px;
            padding: 10px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);

            &:hover {
                animation-name: none;
                //background-color: #41880b;
            }
        }
    }
}


#fbsignup, #fblogin, .fbsignup, .fblogin {
    display: block;
    width: 80%;
    max-width: 350px;
    margin: 0 auto;
    font-size: 1.3em;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px rgba(0, 0, 0, .25);
    text-align: center;
    cursor: pointer;
    padding: 10px;
    background-color: #3b5998;
    border: 1px solid #2d4373;
    transition: background-color .5s;

    &.smallfb {
        font-size: 1em;
        text-transform: none;
    }

    //animation: fbColorChange 2.5s infinite;

    border-radius: 10px;

    .logo {
        fill: #fff;
        width: 1em;
        height: 1em;
        display: block;
        float: left;
    }

    &:hover {
        background-color: #2d4373;   
        animation: fbColorChange 1.5s infinite;
    }

    @media (max-width: @mobileBreakpoint) {
        font-size: 1em;
    }

}

.fbButtonContainer { text-align: center; }

#loginPop {
    padding: 15px;

    h2 {
        color: @red;
        font-family: "Open Sans Condensed",sans-serif;
        text-shadow: 1px 4px 4px rgba(0, 0, 0, .25);
        font-size: 1.8em;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 1.4em;
    }

    #offersignup, #fbButtonContainer {
        text-align: center;
    }

    #logo {
        margin: 0 auto;

        img { max-width: 100%; height: auto; }

        @media (max-width: @mobileBreakpoint) {
            max-width: 150px;
        }
    }

    .offerImg img {
        max-width: 100%;
        height: auto;
    }

    .logo {
        img { max-width: 100px; }
    }

    div.error {
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid #9e0101;
        background-color: #e04444;
        color: #fff;
        text-align: center;
        font-size: .8em;
        font-weight: 700;
        letter-spacing: .08em;
        line-height: 1.4em;
    }

    form.popupOfferForm {
        background-color: #f3f3f3;
        padding: 10px;
        border-radius: 5px;
        text-align: center;

        .input {
            margin-bottom: 8px;

            input[type="text"], input[type="email"], input[type="password"]{
                border: 1px solid #cdd0d0;
                background-color: #fff;
                width: calc(~"100% - 32px");
                font-size: .8em !important;
                border-radius: 3px;
                padding: 10px 15px;
            }
        }

        input {
            outline: 0;
        }

        div.fake {
            padding: 0 15px;
        }

        div.fake > input {
            font-size: 1em;
            border: 0 !important;
        }

        #buttonGroup, .buttonGroup {
            position: relative;

            #titleButtons, .titleButtons {
                width: 80px;
                position: absolute;
                left: -15px;

                button {
                    cursor: pointer;
                    display: block;
                    float: left;
                    padding: 10px 0;
                    font-weight: bold;
                    font-size: 11px;
                    border: 0;
                    width: 40px;
                    text-align: center;
                    color: #757575;
                    background: linear-gradient(to bottom, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
                    border: 1px solid #cdd0d0;

                    &:focus { outline:0; }
                    &:hover { color: #fff; }
                    &.active {
                        color: #fff;
                        box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .25)
                    }
                }

                #mr, .mr { 
                    border-top-left-radius: 3px;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 0;
                    border-right-width: 0;

                    &:hover, &.active {
                        background: linear-gradient(to bottom, @2kColor, darken(@2kColor, 20%));
                    }
                }

                #ms, .ms { 
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    &:hover, &.active {
                        background: linear-gradient(to bottom, @pink, darken(@pink, 20%));
                    }
                }
            }

            input[type='text']{
                font-size: 1em;
                padding: 10px 10px 10px 78px;
                width: calc(~"100% - 73px");
                border: 0;
            }
        }

        #zipdob, .zipdob {
            padding: 5px 15px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            @media (max-width: @mobileBreakpoint) {
                display: block;
                border: 0;
                padding: 0;
                width: 100%;

                #joinzipcode, .joinzipcode, & > .block {
                    border: 1px solid #cdd0d0 !important;
                    background-color: #fff;
                    width: calc(~"100% - 32px");
                    border-radius: 3px;
                    padding: 10px 15px;
                    margin-bottom: 5px;
                }

                & > .block label:first-child {
                    display: block;
                }
            }

            & > input {
                flex-basis: 45%;
                padding: 5px 15px;

                @media (max-width: @mobileBreakpoint) {
                    flex-basis: 100%;
                }
            }

            & > input:first-child{
                padding-left: 0;
            }
            & > input:last-child{
                padding-right: 0;
            }
        }

        button.signup, button.continue {
            display: block;
            border: 0;
            border-radius: 4px;
            max-width: 350px;
            width: 100%;
            padding: 7px 15px;
            margin: 0 auto;
            background-color: @red;
            cursor: pointer;
            
            color: #fff;
            font-size: 1.3em;
            text-transform: uppercase;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
            letter-spacing: .1em;
        }

        .disclaimer {
            color: #555;
            margin: 25px 0 0 0;
            font-size: .7em;
            line-height: 1.3em;

            a { color: #3b5998; }
        }
    }
}

.remodal-overlay.confirmed {
    background-color: transparent;
}

#joinModal, #firstModal, #additionalInfoModal, .joinModal {
    max-width: 800px;
    //background: url(img/header_bg.jpg) 0 0 no-repeat #1572b3;
    //background-size: cover;
    //animation: animatedBackground 40s linear infinite;

    @media (max-width: @mobileBreakpoint) {
        padding: 15px;
    }

    &.white {
        background: #fff;

        h2 {
            color: #771251 !important;
        }

        .remodal-close:before { color: #000; }
    }

    .remodal-close:before {
        color: #000;
    }

    h2 {
        color: #761251 !important; 
        text-shadow: 1px 4px 4px rgba(0, 0, 0, .25);
        margin: 10px 0;
    }

    #logo {
        margin: 0 auto;

        img { max-width: 100%; height: auto; }

        @media (max-width: @mobileBreakpoint) {
            max-width: 150px;
        }
    }

    .fbButtonContainer {
        margin-bottom: 10px;
    }

    .fbsignuptextp { 
        margin: 10px 0 0 0;
    }
    .fbsignuptext {
        color: #3b5998;
    }

    .offerImg img {
        max-width: 100%;
        height: auto;
    }

    .logo {
        img { max-width: 150px; }
    }

    div.error {
        padding: 5px;
        margin-bottom: 10px;
        border: 1px solid #9e0101;
        background-color: #e04444;
        color: #fff;
        text-align: center;
        font-size: .8em;
        font-weight: 700;
        letter-spacing: .08em;
        line-height: 1.4em;
    }

    #popOffer, .popOffer, .bigbutton {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: 1.5em;
        font-weight: 700;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);

        display: block;
        background-color: #761251;
        transition: background-color .5s;
        margin: 20px auto 0;
        max-width: 600px;
        border-radius: 5px;
        padding: 20px 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);

        .pulse();
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;

        &:hover {
            animation-name: none;
            //background-color: #41880b;
        }
    }

    #thankyoumessage {
        background-color: rgba(255, 255,255, .55);
        border-radius: 5px;
        padding: 10px;

        & > p:last-child {
            margin-bottom: 0;
        }
    }

    #offersignup, .offersignup {
        h2 { margin-bottom: 0; }
        p { 
            margin-bottom: 10px; 

            @media (max-width: @mobileBreakpoint) {
                font-size: .8em;
                line-height: 1.3em;
            }
        }
    }

    #signup, #continue, .signup {
        display: block;
        border: 0;
        border-radius: 4px;
        max-width: 350px;
        width: 100%;
        padding: 7px 15px;
        margin: 0 auto;
        background-color: @red;
        cursor: pointer;
        
        color: #fff;
        font-size: 1.3em;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
        letter-spacing: .1em;

        @media (max-width: @mobileBreakpoint) {
            font-size: 1em;
        }
    }

    #joinForm, .joinForm {
        display: none;
        .input {
            input[type="text"], input[type="email"], input[type="password"],  &.fake {
                background-color: rgba(255, 255, 255, .8);
            }

            border: 1px solid #cdd0d0;
        }


        .fields {
            display: flex;
            flex-wrap: no-wrap;
            justify-content: space-between;
            text-align: left;

            & > div {
                flex-basis: 48%;

            }

            @media (max-width: @mobileBreakpoint) {
                flex-wrap: wrap;

                & > div {
                    flex-basis: 100%;

                }
            }
        }
    }

    @media (max-width: @mobileBreakpoint) {
        #fblogin, #fbsignup, .fblogin, .fbsignup {
            width: 100%;
            max-width: auto;
            
            & + small {
                font-size: .7rem;
            }
        }

    }

}

.joinModal {
    .input {
        input[type="text"], input[type="email"], input[type="password"]{
            border: 1px solid #cdd0d0 !important;
        }
    }

    .fake.input {
        input[type="text"], input[type="email"], input[type="password"]{
            border: 0 !important;
        }
    }

    .fake.input.zipdob {
        @media (max-width: @mobileBreakpoint) {
            .joinzipcode {
                border: 1px solid #cdd0d0 !important;
            }
        }
    }
}

#joinOfferForm, .joinOfferForm {
    .input {
        input[type="text"], input[type="email"], input[type="password"]{
            border: 1px solid #cdd0d0;
        }
    }

    input {
        outline: 0;
    }

    div.fake {
        padding: 0 15px;
    }

    div.fake > input {
        font-size: 1em;
        border: 0 !important;
    }

    #buttonGroup, .buttonGroup {
        position: relative;

        #titleButtons, .titleButtons {
            width: 80px;
            position: absolute;
            left: -15px;

            button {
                cursor: pointer;
                display: block;
                float: left;
                padding: 10px 0;
                font-weight: bold;
                font-size: 11px;
                border: 0;
                width: 40px;
                text-align: center;
                color: #757575;
                background: linear-gradient(to bottom, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
                border: 1px solid #cdd0d0;

                &:focus { outline:0; }
                &:hover { color: #fff; }
                &.active {
                    color: #fff;
                    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .25)
                }
            }

            #mr, .mr { 
                border-top-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 0;
                border-right-width: 0;

                &:hover, &.active {
                    background: linear-gradient(to bottom, @2kColor, darken(@2kColor, 20%)) !important;
                }
            }

            #ms, .ms { 
                border-radius: 0 3px 3px 0;

                &:hover, &.active {
                    background: linear-gradient(to bottom, @pink, darken(@pink, 20%)) !important;
                }
            }
        }

        input[type='text']{
            font-size: 1em;
            padding: 10px 10px 10px 78px !important;
            width: calc(~"100% - 73px") !important;
            border: 0;
        }
    }

    #zipdob, .zipdob {
        padding: 5px 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        @media (max-width: @mobileBreakpoint) {
            display: block;
            border: 0;
            padding: 0;
            width: 100%;

            #joinzipcode, .joinzipcode, & > .block {
                border: 1px solid #cdd0d0 !important;
                background-color: #fff;
                width: calc(~"100% - 32px");
                border-radius: 3px;
                padding: 10px 15px;
                margin-bottom: 5px;
            }

            & > .block label:first-child {
                display: block;
            }
        }

        & > input {
            flex-basis: 45%;
            padding: 5px 15px;

            @media (max-width: @mobileBreakpoint) {
                flex-basis: 100%;
            }
        }

        & > input:first-child{
            padding-left: 0;
        }
        & > input:last-child{
            padding-right: 0;
        }
    }

    .disclaimer {
        color: #555;
        margin: 25px 0 0 0;
        font-size: .7em;
        line-height: 1.3em;

        a { color: #3b5998; }
    }
}

#page {
    padding: 20px 0;

    .container img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 15px auto;
    }
}

form#reset_pass {
    display: block;
    margin: 0 auto 15px;
    padding: 10px;

    text-align: center;

    input {
        font-size: 1em;
        text-align: center;
        width: calc(~"100% - 8px");
        padding: 3px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

    button {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: .8em;
        font-weight: 700;
        text-shadow: 1px 1px rgba(0, 0, 0, .25);
        cursor: pointer;

        display: block;
        width: calc(~"100% - 10px");
        margin: 0 auto;
        background-color: #761251;
        max-width: 400px;
        border-radius: 5px;
        border: 0;
        padding: 10px 5px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);
    }
}

.trioffer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > div {
        flex-basis: 300px;

        @media (max-width: 768px) {
            flex-basis: 100%;
        }
    }
}

.message {
    font-size: .9em;
    text-align: center;
    padding: 10px;
    background-color: #d2e7f3;
    border: 1px solid darken(#82c3d7, 10%);
    border-radius: 3px;
    margin: 10px 0;

    &.success {
        background-color: #d2f3d6;
        border: 1px solid #67cb5b;
    }

    &.error {
        color: #fff;
        background-color: #b71111;
        border: 1px solid #cb5b5b;
    }
}

.button {
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1px 1px rgba(0, 0, 0, .25);

    display: block;
    background-color: #761251;
    transition: background-color .5s;
    margin: 50px auto 0;
    max-width: 400px;
    border-radius: 5px;
    padding: 20px 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);

    &:hover {
        background-color: lighten(#761251, 10%);
    }

    &.pulse {
        animation-duration: 2s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;

        .pulse();
    }
}

#desktopLoadMore {
    margin-bottom: 15px;
}


//Offer page specific styling
body.offer {
    @media (max-width: @mobileBreakpoint) {
        #headline { display: none; }
        #topslot { display: block !important; padding: 0 !important; }
        #middleSlot .container { padding: 0; }
    }
}

@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 100% 0; }
}

@keyframes fbColorChange {
    0% { background-color: #3b5998; }
    50% { background-color: #2d4373; }
    100% { background-color: #3b5998; }
}

.responsive_wide { width: 300px; height: 50px; margin: 0 auto; }
@media(min-width: 320px) { .responsive_wide { width: 320px; height: 50px; } }
@media(min-width: 500px) { .responsive_wide { width: 468px; height: 60px; } }
@media(min-width: 800px) { .responsive_wide { width: 728px; height: 90px; } }

.responsive_block { width: 300px; height: 250px; margin: 0 auto; }
@media(min-width: 500px) { .responsive_block { max-height: 250px !important; } }


.bar-container {
    margin: 0 auto;
    max-width: 750px;
    box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
    padding: 4px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .55);
    text-align: left;


    .progress-bar {
        background-color: #771251;
        width: 100%;
        min-height: 15px;
        border-radius: 10px;
        position: relative;
        overflow: visible !important;

        .points {
            position: absolute;
            right: -11px;
            bottom: -28px;
            background-color: green;
            color: #fff;
            padding: 2px 4px;
            font-size: .8em;
            border-radius: 4px;
            transition: background-color .25s;
            z-index: 900;

            &.warning { background-color: orange; }
            &.danger { background-color: darkred; }
        }
    }
}

.progress-bar.active, .progress.active .progress-bar {
    animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-striped, .progress-striped .progress-bar {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 40px 40px;
}

@keyframes progress-bar-stripes {
    from {
        background-position:40px 0
    } to {
        background-position:0 0
    }
}

body#trivia {
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .headline { color: #fff; }

    .block { width: 100%; flex-basis: 100%; }

    header { width: 100%; height: fit-content; }

    header .logo {
        position: absolute;
        left: 10px;
        top: 5px;

        img { width: auto; height: 60px; }

        @media (max-width: @tabletBreakpoint) {
            position: static;
            display: block;
            text-align: center;
        }

    }

    @media (min-width:450px) and (max-width: 800px) {
        .landscape_hide { display: none; }
    }

    header > .container {
        overflow: visible;
        display: flex;
        justify-content: center;
        min-height: 73px;

        #headerbar { padding-bottom: 5px; }

        @media (max-width: @mobileBreakpoint) {
            display: block;
            overflow: visible;

            .logo {
                display: block;
                padding: 5px 0 0 0;
                text-align: center;
                width: 100px;
                margin: 0 auto;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            #headerbar .headline {
                margin-top: 5px;
                font-size: .8em;
            }
        }

    }

    footer {
        width: 100%;
        padding: 15px 0;
        font-size: .8em;

        flex-shrink: 0;
        width: 100%;
        height: fit-content;
        align-self: flex-end;
    }
}

#triviaContainer {
    width: calc(~"100% - 80px");
    max-width: 700px;
    background-color: rgba(0, 0, 0, .75);
    padding: 40px;
    margin: 40px auto;
    border-radius: 10px;
    text-align: center;

    color: #fff;

    @media (max-width: 800px) {
        margin-top: 10px;
        padding-top: 0;
    }

    @media (max-width: @mobileBreakpoint) {
        margin: 10px auto;
        padding: 25px 10px;
        width: calc(~"100% - 20px");
    }

    h1 {
        color: #fff;
        font-weight: 700;
        margin-top: 0;
    }

    h2 {
        color: #fff;
        @media (max-width: 800px) {
            margin-top: 0;
        }

        @media (max-width: @mobileBreakpoint) {
            &.question-count { margin-top: 0; }
        }
    }

    .step:not(:first-child){
        display: none;
    }


    .step {
        .question {
            display: block;
            background-color: #0a86b5;
            padding: 15px;
            border-radius: 8px;
            text-shadow: 1px 1px rgba(0, 0, 0, .2);
            font-weight: 700;
        }

        button.answer {
            display: inline-block;
            font-size: 1em;
            font-weight: 700;
            text-transform: uppercase;
            color: #fff;
            padding: 8px 20px;
            background-color: #039e03;
            border: 0;
            cursor: pointer;
            border-radius: 6px;
            text-shadow: 1px 1px rgba(0, 0, 0, .3);
            margin-top: 12px;
            transition: background-color .5s;
            min-width: 150px;

            &.no {
                background-color: #9e0303;
            }
        }

        strong.correct, .timeout strong { color: #039e03; }
        strong.yes, strong.no { padding: 4px 8px; color: #fff; background-color: #039e03; border-radius: 4px; }
        strong.no { background-color: #e40f0f; }

        .result {
            .correct, .incorrect { border-radius: 4px; padding: 4px; }
            .correct { background-color: #039e03; }
            .incorrect { background-color: #e40f0f; }
        }

        .points {
            font-weight: 700;
            color: #fff;
            padding: 4px 8px;
            background-color: #039e03;
            border-radius: 4px;
            text-shadow: 1px 1px rgba(0, 0, 0, .3);
        }

        .timeout { display: none; }
    }

    .offer-single-content {
        .container {
            & > .offer {
                margin: 0 auto 10px;
                float: none;
            }

            & > .copy {
                width: 100%;
                float: none;

            }

        }

        .fbButtonContainer small, .joinLabel > span {
            color: #c3c3c3;
        }
    }

}

//Special offer signup form - inline
.offer-single-content {
    margin: 10px 0;
    overflow: visible;

    .offerButton.mobile-only {
        margin-top: 15px;
    }

    .fbButtonContainer {
        padding: 5px 0;

        small {
            font-size: .6rem;
            color: #616161;
        }

        h2 {
            padding-bottom: 7px;
            font-size: 1.2em;
        }
    }

    .joinOfferForm {
        .error {
            font-size: .8rem;
            color: #fff;
            background-color: #a51212;
            padding: 5px;
            text-align: center;
            border: 1px solid #f12e2e;
            margin-bottom: 10px;
        }

        div.fake {
            background-color: #fff;
            padding: 0;
            border: 1px solid #cdd0d0;

            @media (max-width: @mobileBreakpoint) {
                background-color: transparent;
                padding: 0 !important;
                border: 0;
            }

            &.zipdob {
                padding: 0 10px;
                text-align: left;
                @media (max-width: @mobileBreakpoint) {
                    flex-wrap: wrap;
                }

                .block {
                    padding: 10px;
                    @media (max-width: @mobileBreakpoint) {
                        width: calc(~"100% - 20px");
                    }
                }

                input[name="zip"] {
                    padding: 0;
                    
                    @media (max-width: @mobileBreakpoint) {
                        width: calc(~"100% - 20px");
                        padding: 10px;
                        font-size: 1em;
                    }
                }
            }

            .titleButtons {
                left: 1px;
                top: 1px;
            }

            input[name="full_name"]{
                padding-left: 91px !important;
                width: calc(~"100% - 101px") !important;
            }

            label { color: #757575; }

        }

        .input {
            margin-bottom: 5px;

            input[type="text"], input[type="password"], input[type="email"] {
                font-size: 1em;
                padding: 10px;

                &[name="email"], &[name="password"]{
                    width: calc(~"100% - 22px");
                }
            }
        }

        .joinLabel {
            margin-top: 15px;
            margin-bottom: 5px;
        }

        button.signup, button.continue {
            display: block;
            border: 0;
            border-radius: 4px;
            max-width: 350px;
            width: 100%;
            padding: 7px 15px;
            margin: 0 auto;
            background-color: @red;
            cursor: pointer;
            
            color: #fff;
            font-size: 1.3em;
            text-transform: uppercase;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
            letter-spacing: .1em;
        }

        .linkbuttons {
            border-top: 1px solid #d8d5d5;
            margin-top: 10px;

            a {
                display: block;
                font-size: .8rem;
                border-radius: 4px;
                padding: 4px;
                text-align: center;
                text-decoration: none;
                box-sizing: border-box;
                margin: 8px auto 0;
                border: 1px solid #1465de;
                color: #f7f7f7;
                background-color: #1b6eb7;
                text-transform: uppercase;
                letter-spacing: .05em;
                max-width: 350px;
            }
        }

    }

    &.variation_1 {
        .fbsignup {
            width: 100%;
            font-size: 1.5em;
            box-sizing: border-box;
            max-width: 400px;
            padding: 20px 10px;
            .pulse();
            animation-duration: 5s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;

        }

        .offerButton {
            animation-duration: 5s;
            text-transform: uppercase;
            box-sizing: border-box;
            &:hover {
                animation-name: pulse;
            }
        }


        @media (max-width: @mobileBreakpoint) {
            .fbsignup, .offerButton {
                font-size: 1.1em;
                padding: 10px;
            }
        }
    }
}


body.highlight-is-active {
  pointer-events: none;
}

#pushboxcontainer {
  pointer-events: all;
}

.highlight {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .8);
  position: relative;
  z-index: 9999;
  pointer-events:  auto;
  transition: all 0.5s ease;
}
//@import (inline) "odometer-theme-default.css";

@import "coinflip.less";
